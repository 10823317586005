<template>
    <div class="DashboardVideoView" :class="{ loading: !loaded }">
        <div ref="detailSection" class="detailSection">
            <div ref="videoSectionPlaceholderMin" class="videoSectionPlaceholderMin">
                <div
                    ref="minVideoPlaceholder"
                    class="aspectHelper"
                    :style="{ paddingTop: `${(1 / aspectRatio) * 100}%` }"/>
                <div class="progressBar" />
            </div>
            <div ref="videoSectionPlaceholder" class="videoSectionPlaceholder">
                <div ref="exitButtonPlaceholder" class="exitButtonPlaceholder" />
                <div
                    ref="maxVideoPlaceholder"
                    class="aspectHelper"
                    :style="{ paddingTop: `${(1 / aspectRatio) * 100}%` }"/>
                <div class="progressBar" />
            </div>
            <div class="detailSectionContent">
                <template v-if="loaded">
                    <div class="title">
                        {{ video.title }}
                    </div>

                    <div class="source">
                        <div v-if="video.channelIcon" class="sourceLogo">
                            <img :src="video.channelIcon" />
                        </div>
                        <div class="sourceName">
                            {{ video.channelTitle }}
                        </div>
                    </div>
                    <div v-if="!showingDescription" class="showMoreButtonContainer">
                        <div class="showMoreButton --global-clickable" @click="showDescription()">
                            {{ $t('general.showMore') }} <svgicon class="showMoreIcon" icon="chevron-down" />
                        </div>
                    </div>
                    <template v-if="showingDescription && descriptionLoaded">
                        <div class="description" v-html="description" />
                        <div class="showLessButtonContainer">
                            <div class="showLessButton --global-clickable" @click="hideDescription()">
                                {{ $t('general.showLess') }} <svgicon class="showLessIcon" icon="chevron-down" />
                            </div>
                        </div>
                    </template>
                    <div v-else-if="showingDescription">
                        <div class="loadingIndicator">
                            <svgicon class="loadingIcon" icon="loadwheel" />
                        </div>
                    </div>

                    <div v-t="'partsExplanation'" class="partsExplanation" />

                    <VerticalTimeline :items="parts">
                        <div
                            slot="bullet"
                            slot-scope="props"
                            class="PartBullet"
                            :class="{
                                completed: props.item.completed,
                                locked: props.item.locked,
                                '--global-clickable': !props.item.locked,
                            }"
                            :style="{ backgroundColor: theme.color }"
                            @click="playPart(props.index)">
                            <CircularProgress
                                v-if="!props.item.completed && !props.item.locked"
                                :progress="props.item.progress"
                                :hide-track="true"
                                :color="theme.color"/>
                            <div
                                v-if="!props.item.completed && !props.item.locked"
                                class="PartBullet__dot"
                                :style="{ backgroundColor: theme.color }"/>
                            <svgicon v-if="props.item.completed" class="PartBullet__checkmark" icon="checkmark" />
                            <svgicon v-else-if="props.item.locked" class="PartBullet__checkmark" icon="lock3" />
                        </div>
                        <div
                            slot="content"
                            slot-scope="props"
                            :class="{ '--global-clickable': !props.item.locked }"
                            @click="playPart(props.index)">
                            <div class="Part__label" :style="{ color: theme.color }">
                                <span v-t="'part'" /> {{ props.index + 1 }}
                            </div>
                            <div class="Part__timeRange">
                                {{ props.item.start | timestamp }} - {{ props.item.end | timestamp }}
                            </div>
                        </div>
                    </VerticalTimeline>
                </template>
                <div v-else class="loadingIndicator">
                    <svgicon class="loadingIcon" icon="loadwheel" />
                </div>
            </div>
        </div>
        <div ref="videoSection" class="videoSection">
            <div
                v-if="loaded"
                class="video"
                :style="{
                    width: `${(1 - lastStickyFraction) * (maxVideoWidth - minVideoWidth) + minVideoWidth}px`,
                    marginTop: `${topbarHeight * (1 - lastStickyFraction)}px`,
                }">
                <YoutubeVideo
                    ref="video"
                    :youtube-id="video.id"
                    :duration="video.duration"
                    :thumbnail="video.thumbnail"
                    :initial-playhead="videoController.playhead"/>

                <VideoProgressBar
                    class="progress"
                    :duration="video.duration"
                    :progress="video.progress"
                    :playhead="videoController.playhead"
                    :parts="videoController.parts"
                    @playhead-request="onPlayheadRequest"/>
            </div>
            <div v-else class="loadingIndicator">
                <svgicon class="loadingIcon loadingIcon--white" icon="loadwheel" />
            </div>
            <div ref="exitButton" class="exitButton --global-clickable" @click="exit()">
                {{ $t('back') }}
            </div>
        </div>
    </div>
</template>

<translations>
  part: 'Part'
  part_no: 'Del'

  partsExplanation: 'All videos are split into 3 minute brain dates, so that you can enjoy them in parts.'
  partsExplanation_no: 'Alle videoene er inndelt i 3-minutters brain dates, slik at du kan se dem i deler.'

  back: Back
  back_no: Tilbake
</translations>

<script>
import categoryThemes from '@/category-themes';
import { EventBus } from '@/event-bus';

import CircularProgress from '@/components/CircularProgress'
import VerticalTimeline from '@/components/VerticalTimeline'

import YoutubeVideo from '../components/YoutubeVideo';
import VideoProgressBar from '../components/VideoProgressBar';

import VideoController from '../VideoController';

function pad(num) {
    return ('0' + num).slice(-2);
}
function hhmmss(secs) {
    let minutes = Math.floor(secs / 60);
    secs = secs % 60;
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    return (hours ? pad(hours) + ':' : '') + pad(minutes) + ':' + pad(secs);
}
function timestampFilter(seconds) {
    return hhmmss(seconds);
}

export default {
    name: 'DashboardVideoView',
    components: {
        VerticalTimeline,
        CircularProgress,
        YoutubeVideo,
        VideoProgressBar,
    },
    filters: {
        timestamp: timestampFilter,
    },
    props: {
        videoId: {
            type: String,
        },
        lifeskillId: {
            type: String,
        },
    },
    provide() {
        return {
            theme: this.theme,
        };
    },
    data() {
        return {
            theme: categoryThemes.default,
            videoController: null,
            loaded: false,

            lastStickyFraction: 0,
            maxH: 0,
            minH: 0,

            topbarHeight: 0,
            maxVideoWidth: 0,
            minVideoWidth: 0,
            aspectRatio: 16 / 9,

            showingDescription: false,
            descriptionLoaded: false,
        };
    },
    computed: {
        parts() {
            return this.videoController.parts;
        },
        description() {
            if (!this.video.description) {
                return '';
            }
            const originalString = this.video.description;
            const expression = /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.,~#?&()//=]*)?/gi;

            const regex = new RegExp(expression);

            return originalString.replace(regex, function (link) {
                if (link.length > 40) {
                    return `<a href="${link}">${link.substr(0, 37)}...</a>`;
                } else {
                    return `<a href="${link}">${link}</a>`;
                }
            });
        },
    },
    async mounted() {
        // this.video = await memomapsDataHelper.loadVideo(this.videoId) || {}
        await this.$store.dispatch('moduleApp/loadVideo', this.videoId);
        this.video = this.$store.getters['moduleApp/getVideoById'](this.videoId);

        this.descriptionLoaded = true;
        this.videoController = new VideoController(this.video.id, this.lifeskillId);
        this.loaded = true;
        await this.$nextTick();
        this.videoController.setPlayer(this.$refs.video);

        this.lastStickyFraction = 0;
        this.$refs.detailSection.addEventListener('scroll', this.handleScroll);
        EventBus.$on('resize-end', this.handleResize);
        this.handleResize();
    },

    beforeDestroy() {
        this.videoController.destroy();
        this.$refs.detailSection.removeEventListener('scroll', this.handleScroll);
        EventBus.$off('resize-end', this.handleResize);
    },
    methods: {
        handleScroll(forceUpdate = false) {
            let scrollTop = this.$refs.detailSection.scrollTop;
            let stickyFraction = 1;
            if (scrollTop > this.maxH - this.minH) {
                stickyFraction = 1;
            } else {
                stickyFraction = Math.max(0, scrollTop) / (this.maxH - this.minH);
            }
            if (forceUpdate || this.lastStickyFraction !== stickyFraction) {
                this.$refs.videoSection.style.height = `${
                    (1 - stickyFraction) * (this.maxH - this.minH) + this.minH
                }px`;
                this.lastStickyFraction = stickyFraction;
            }
        },
        handleResize() {
            this.topbarHeight = this.$refs.exitButtonPlaceholder.clientHeight;
            this.minH = this.$refs.videoSectionPlaceholderMin.clientHeight;
            this.maxH = this.$refs.videoSectionPlaceholder.clientHeight;

            this.minVideoWidth = this.$refs.minVideoPlaceholder.clientWidth;
            this.maxVideoWidth = this.$refs.maxVideoPlaceholder.clientWidth;

            this.handleScroll(true);
        },
        onPlayheadRequest(position) {
            this.videoController.seek(position);
            this.videoController.play();
        },
        playPart(index) {
            let part = this.parts[index];
            if (!part.locked) {
                this.videoController.seek(part.start);
                // this.videoController.play();
            }
        },
        exit() {
            if (this.exitUrl) {
                return this.$router.push(this.exitUrl);
            } else {
                this.$router.push({
                    name: 'MemolifeTvView',
                });
            }
        },
        showDescription() {
            this.showingDescription = true;
        },
        hideDescription() {
            this.showingDescription = false;
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.fullPath !== '/' && !from.fullPath.startsWith('/login?')) {
                vm.exitUrl = from.fullPath;
            }
        });
    },
};
</script>

<style lang="scss" scoped>
$breakpoint: 950px;

.DashboardVideoView {
    background-color: white;
}

.exitButton {
    color: rgba(white, 0.9);
    position: absolute;
    padding: 1em;
    top: 0;
    left: 0;
}

.videoSection {
    background-color: #2b2b2b;
}

.video,
.aspectHelper {
    width: 100%;
    max-width: 70em;
}

.aspectHelper {
    // background-color: rgba(blue, 0.1);
}

.progress {
    margin: 0em auto;
    margin-top: 0.5em;

    margin-left: 0.9em; // Trying to match the margins of the interal youtube player progressbar
    margin-right: 0.9em;
    // height: 2em;
    // outline: 1px solid red;
}

.loadingIndicator {
    display: flex;
    justify-content: center;
}
.loadingIcon {
    $size: 2em;
    width: $size;
    height: $size;
    fill: black;
    animation: rotate360 2s linear infinite;
}
.loadingIcon--white {
    fill: white;
}
@keyframes rotate360 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.title {
    font-size: 140%;
    word-wrap: break-word;
}

.description {
    margin-top: 2em;
    font-size: 90%;
    line-height: 1.3em;
}

.source {
    display: flex;
    align-items: center;
    margin-top: 0.5em;
}
.sourceLogo {
    $size: 2em;
    height: $size;
    margin-right: 1em;
    img {
        height: 100%;
    }
}

.partsExplanation {
    margin-top: 2em;
    line-height: 1.5em;
    color: rgba(black, 0.7);

    &::after {
        content: '';
        display: block;
        width: 50%;
        margin-top: 1em;
        height: 1px;
        background-color: rgba(black, 0.05);
        margin-bottom: 1em;
    }
}

.showLessButtonContainer,
.showMoreButtonContainer {
    display: flex;
    justify-content: flex-end;
}
.showMoreButton,
.showLessButton {
    color: $standardButtonColor;
    font-size: 90%;
    display: flex;
    align-items: center;

    padding: 0.5em;
    background-color: rgba($standardButtonColor, 0.05);
    border: 1px solid rgba($standardButtonColor, 0.05);
}
.showMoreIcon,
.showLessIcon {
    $size: 1em;
    width: $size;
    height: $size;
    fill: $standardButtonColor;
    margin-left: 1em;
}
.showLessButton {
    margin-top: 1em;
    // margin-left: auto;
}
.showLessIcon {
    transform: rotate(-180deg);
}

$bulletSize: 2em;

.PartBullet {
    position: relative;
    width: $bulletSize;
    height: $bulletSize;
    background-color: green;
    border-radius: 50%;
    // margin: 4px;

    box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);

    &.completed,
    &.locked {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:not(.completed) {
        background-color: white !important;
    }
    &.locked {
        background-color: white !important;

        .PartBullet__checkmark {
            fill: rgba(0, 0, 0, 0.4) !important;
        }
    }

    .PartBullet__checkmark {
        $size: 1em;
        width: $size;
        height: $size;
        fill: white;
    }
}
.PartBullet__dot {
    $size: 40%;
    position: absolute;
    width: $size;
    height: $size;
    border-radius: 50%;
    top: calc(50% - #{$size / 2});
    left: calc(50% - #{$size / 2});
}

.VerticalTimeline {
    /deep/ .Step {
        .bullet {
            margin-right: 1.5em;
        }
    }
}

@include media('>=#{$breakpoint}') {
    .DashboardVideoView {
        display: flex;

        &.loading {
            .detailSection {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .videoSectionPlaceholder,
    .videoSectionPlaceholderMin {
        display: none;
    }
    .videoSection {
        width: 80%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto !important;
        padding: 1em;
    }

    .video {
        width: 100% !important;
        max-width: 70em !important;
    }

    .detailSection {
        flex: 1;
        padding: 2em;
        min-width: 20em;
        overflow-y: auto;
        order: 1;
    }
}
@include media('<#{$breakpoint}') {
    .DashboardVideoView {
        position: relative;

        &.loading {
            .videoSection {
                min-height: 8em;
            }
        }
    }
    .videoSection {
        position: absolute;
        top: 0;
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .progressBar {
        height: 2em; //5px;
        margin-top: 0.5em;
    }

    .videoSectionPlaceholderMin {
        position: absolute;
        padding: 0.5em 0;
        .aspectHelper {
            width: 8em;
        }
    }
    .videoSectionPlaceholder {
        padding: 1em 1em;
    }
    .minVideoPlaceholder {
        width: 8em;
    }
    .exitButtonPlaceholder {
        height: 2em;
    }
    .detailSection {
        overflow-y: auto;
        height: 100%;
    }
    .detailSectionContent {
        margin: auto;
        padding: 2em;
        padding-top: 1em;
        max-width: 40em;
    }
}
</style>
