<template>
  <div
      class="VideoProgressBar">
    <div
      v-for="(part, i) of parts"
      :key="i"
      class="Mark"
      :style="{ left: `${(part.end / duration) * 100}%` }" />
  </div>
</template>

<script>
export default {
    props: {
        duration: Number,
        progress: Number,
        playhead: Number,
        parts: {
            type: Array
        }
    },

    mounted() {
    }
};
</script>

<style lang="scss" scoped>
.VideoProgressBar {
    position: relative;
    background-color: white;
    &::after {
        // NOTE: Larger click area
        position: absolute;
        content: '';
        display: block;
        top: -0.5em;
        left: -0.5em;
        right: -0.5em;
        bottom: -0.5em;
    }
}
.indicator {
    position: relative;
    height: 5px;
    background-color: rgba($standardButtonColor, 0.5);
}
.playhead {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: $standardButtonColor;
    pointer-events: none;
}
.Mark {
    width: 1px;
    height: 0.8em;
    background-color: rgba(white, 0.5);
    position: absolute;
    top: 0;
}
</style>
