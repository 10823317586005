<template>
  <div
    class="YoutubeVideo"
    :class="{ loading, loaded }">
    <div
      class="aspectHelper"
      :style="{ paddingTop: `${(1 / aspectRatio) * 100}%` }" />
    <div
      ref="host"
      class="video" />
    <!-- <div
      class="thumbnail --global-clickable"
      :style="{ backgroundImage: `url(${thumbnail})` }"
      @click="loadAndPlay()"
    >
      <div class="playIcon">
        <svgicon
          class="svg-icon"
          icon="play-triangle"
        />
      </div>
    </div> -->
    <!-- <div class="skipAheadMessage">
      You can not jump ahead in in the video to parts you have not watched before ... pause video move back
    </div> -->
  </div>
</template>

<script>
import YouTubePlayer from 'youtube-player';

const PlayerState = {
    BUFFERING: 3,
    ENDED: 0,
    PAUSED: 2,
    PLAYING: 1,
    UNSTARTED: -1,
    CUED: 5
};

export default {
    props: {
        youtubeId: {
            type: String,
            required: true
        },
        duration: {
            type: Number,
            required: true
        },
        initialPlayhead: {
            type: Number,
            default: 0
        },
        thumbnail: {
            type: String
        },
        aspectRatio: {
            type: Number,
            default: 16 / 9
        }
    },
    data() {
        return {
            state: 'initial',
            loading: false,
            loaded: false
        };
    },
    mounted() {
        this.loadIfNeeded();
    },
    beforeDestroy() {
        this.stopClock();
    },
    methods: {
        onPlayerStateChange(event) {
            // console.log('onPlayerStateChange', event, event.data)
            if (event.data === PlayerState.ENDED) {
                this.state = 'ended';
                // this.stopClock()
                this.$emit('position', this.duration);
            } else if (event.data === PlayerState.PLAYING) {
                this.state = 'playing';
                // this.startClock()
            } else if (event.data === PlayerState.PAUSED) {
                this.state = 'paused';
                // this.stopClock()
            } else if (event.data === PlayerState.BUFFERING) {
                // this.stopClock()
            } else if (event.data === PlayerState.CUED) {
                // this.stopClock()
            }
        },
        startClock() {
            this.vidClock = setInterval(() => {
                this.onTick();
            }, 100);
        },
        stopClock() {
            if (this.vidClock) {
                clearInterval(this.vidClock);
                this.vidClock = null;
            }
        },
        onTick() {
            this.player.getCurrentTime().then(time => {
                this.$emit('position', time);
            });
        },

        loadIfNeeded(position) {
            if (!this.loaded && !this.loading) {
                this.loading = true;
                this.player = YouTubePlayer(this.$refs.host, {
                    height: '100%',
                    width: '100%',
                    playerVars: {
                        autoplay: 0,
                        rel: 0,
                        // controls: 0,
                        modestbranding: 1, // No effect when disabling showinfo
                        // showinfo: 0,
                        // nologo: 1
                        fs: 0, // NOTE: preventing full screen. Necessary in order to allow our own overlay message when illegaly skipping ahead in the video
                        host: 'https://www.youtube.com',
                        //
                        enablejsapi: 1,
                        origin: location.origin
                    }
                });

                this.player.on('stateChange', this.onPlayerStateChange);
                this.player.on('ready', () => {
                    // console.log('READY')
                });
                // cueVideoById
                // this.player.loadVideoById(this.youtubeId, position || this.initialPlayhead).then(() => {
                //   this.loading = false
                //   this.loaded = true
                // })
                this.player.cueVideoById(this.youtubeId, position || this.initialPlayhead).then(() => {
                    // this.player.cueVideoById(this.youtubeId, position || this.initialPlayhead).then(() => {

                    // console.log('CUE DONE')
                    // this.loading = false
                    // this.loaded = true
                    this.startClock();
                });

                return true;
            }
            return false;
        },

        mustLoad() {
            return !this.loaded && !this.loading;
        },

        async loadAndPlay() {
            this.loadIfNeeded();
        },

        // Public methods:

        async start() {
            if (!this.loadIfNeeded()) {
                this.player.playVideo();
            }
        },
        stop() {
            if (this.player) {
                this.player.pauseVideo();
            }
            // this.stopClock()
        },
        async seek(position) {
            if (!this.loadIfNeeded(position)) {
                this.player.seekTo(position, true);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.YoutubeVideo {
    position: relative;

    &.loading {
        .playIcon {
            animation: pulse-anim 1s infinite;
        }
    }
    &.loaded {
        .thumbnail {
            display: none;
        }
    }
}

.YoutubeVideo /deep/ .video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.skipAheadMessage {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: white;
    color: black;

    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.playIcon {
    $size: 2.6em;
    width: $size;
    height: $size;
    top: calc(50% - #{$size / 2});
    left: calc(50% - #{$size / 2});
    border-radius: 50%;
    background-color: white;
    position: absolute;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    box-shadow: 0 0 0.5em rgba(black, 0.2);

    .svg-icon {
        $size: 1em;
        display: block;
        width: $size;
        height: $size;
        fill: black(white, 0.9);
    }
}

@keyframes pulse-anim {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}

.thumbnail {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-size: cover;
    background-position: center;
}
</style>
